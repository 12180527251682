.pdf_parent_container {
    position: fixed;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 2px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
}
.pdf_container {
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    background-color: white;
    width: 100% !important;
    height: 100% !important;
}

.pdf_footer {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    max-width: 600px !important;
    width: 100%;
    margin-top: 10px;
    background: white;
}

.pdf_btn_container > button {
    background-color: transparent;
    cursor: pointer;
}
@media only screen and (max-width: 850px) {
    .react-pdf__Page__canvas {
        max-width: 80vw !important;
    }
    .pdf_footer {
        max-width: 75vw !important;
    }
}
@media only screen and (max-width: 750px) {
    .pdf_container {
        overflow: hidden;
    }
}
