.complianceAndRisk {
    background: url("../../../assets/media/complianceAndRisk_banner.svg")
        no-repeat right;
    background-size: 50%;
    min-height: 400px;
}

.complianceAndRiskMore {
    background-size: 50%;
    min-height: 400px;
    margin-top: 110px;
}

.principlesBox {
    width: 100%;
    box-sizing: border-box;
    margin-top: 100px;
    padding: 120px 0px 50px 0px;
    background-color: var(--color-primary);
    border-radius: 16px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.principlesBox__list {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 1000px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: "Public-Sans-Reg", serif;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
}

.principlesBox__list__item {
    max-width: 450px;
    min-width: 200px;
    margin-bottom: 20px;
    width: 50%;
}

.principlesBox__list__item:before {
    content: url("../../../assets/media/icons/tick_square.svg");
    margin-right: 20px;
    width: 24px;
    vertical-align: middle;
}

.principlesBox:before,
.principlesBox:after {
    border-radius: 1000px / 50px;
    position: absolute;
    background: #f2f2f2;
    height: 66px;
    content: "";
    right: -50px;
    left: -50px;
    top: -50px;
}

.principlesBox:after {
    bottom: -50px;
    top: auto;
}

@media only screen and (max-width: 1100px) {
    .principlesBox__list {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
}
