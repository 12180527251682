.smallTitle__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 128px;
}

.description {
    justify-content: center;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;
    text-align: center;
}

.marketplace__container {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    margin: 120px 0 0 0;
    .col {
        div {
            margin-bottom: 15px;
        }
        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 42px;
        }
    }
}
