.text {
    margin-right: 5px;
    padding: 0 4px 0 4px;
    margin-bottom: 10px;
    width: auto;
    white-space: pre-line;

    &.green {
        color: var(--color-primary-green);
    }
    &.black {
        color: var(--color-primary-black);
    }
    &.white {
        color: var(--color-primary-light);
    }
    &.blue {
        color: var(--color-primary);
    }
    &.xs {
        font-size: 14px;
    }
    &.sm {
        font-size: 15px;
    }
    &.md {
        font-size: 16px;
    }
    &.lg {
        font-size: 24px;
    }
    &.xl {
        font-size: 38px;
    }
    &.underline {
        text-decoration: underline 3px;
    }
    &.bold {
        font-weight: bold;
    }
    &.backgroundGreen {
        background-color: var(--color-primary-green);
    }
    &.backgroundBlue {
        background-color: var(--color-primary);
    }
    &.backgroundBlack {
        background-color: var(--color-primary-black);
    }
    &.center {
        text-align: center;
    }
}
