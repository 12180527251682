.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 999;
    p {
        font-size: 16px;
        font-weight: 400;
    }
    &.absolute {
        position: absolute;
    }
    &.center {
        justify-content: center;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    &.primary--solid {
        background: var(--color-primary);
        color: white;
        border: 3px solid var(--color-primary);
        &:hover {
            background: var(--color-primary);
            color: white;
        }
    }
    &.secondary--solid {
        background: var(--color-primary-green);
        color: white;
        border: 2px solid var(--color-primary-green);
        &:hover {
            background: white;
            border: 2px solid var(--color-primary-green);
            color: var(--color-primary-green);
        }
    }
    &.secondary--circle {
        background: var(--color-primary-green);
        border-radius: 100%;
        width: 59px !important;
        height: 59px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &.primary--outline {
        background: var(--color-primary-light);
        color: #0529a8;
        border: 2px solid var(--color-primary);
        &:hover {
            background: var(--color-primary);
            color: white;
        }
    }

    &.lg {
        font-size: 18px;
        max-width: 240px;
        width: 100%;
        height: 48px;
    }
    &.md {
        font-size: 18px;
        max-width: 200px;
        width: 100%;
        height: 60px;
    }
    &.sm {
        font-size: 14px;
        width: 140px;
        height: 45px;
    }
    &.auto {
        font-size: 14px;
        height: 55px;
        width: 100%;
    }
}
