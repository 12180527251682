.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    width: 100%;
}

.image {
    max-width: 480px;
    width: 100%;
    max-height: 510px;
    height: 90%;
}

.box {
    position: absolute;
    z-index: -1;
    margin-right: 0;
    max-width: 550px;
    max-height: 400px;
    height: 90%;
    width: 110%;
    border-radius: 16px;
    background-color: var(--color-primary);
}
