.card {
    max-width: 350px;
    width: 100%;
    height: 100%;
    padding: 40px 38px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 25px 25px -13px rgba(9, 56, 177, 0.05);
    border-radius: 16px;
    text-align: center;
}

.card__title {
    margin-top: 23px;
    font-family: "Public-Sans", serif;
    font-size: 23px;
    line-height: 28px;
}

.card__description {
    margin-top: 12px;
    font-family: "Public-Sans-Light", serif;
    font-size: 16px;
    line-height: 19px;
}

@media only screen and (max-width: 460px) {
    .card__description {
        font-size: 13px;
    }
    .card__title {
        font-size: 19px;
    }
}
