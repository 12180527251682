.about-us__list-container {
    margin-top: 160px;
    text-align: center;
}

.about-us__missions {
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    justify-content: space-evenly;
}

.mission__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 32px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 25px 25px -13px rgba(9, 53, 177, 0.08);
    border-radius: 10px;
    margin-right: 0;
    font-family: "Public-Sans-Reg", serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.mission__icon {
    max-width: 82px;
    width: 100%;
}

.mission__text {
    margin-top: 20px;
}

.about-us__payers {
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.payer__item {
    max-width: 190px;
    width: 100%;
    margin: 20px;
    height: 190px;
    line-height: 190px;
    background-color: #ffffff;
    border-radius: 50%;
    text-align: center;
}

.payer__logo {
    vertical-align: middle;
}

@media only screen and (max-width: 1180px) {
    .about-us__missions {
        flex-direction: column;
    }
    .mission__item {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        font-weight: normal;
        font-size: 18px;
    }
    .mission__icon {
        margin-right: 20px;
    }
}
