.contact-us__banner {
    position: absolute;
    right: 13%;
    bottom: -50px;
}

.blueBackground {
    position: absolute;
    box-sizing: border-box;
    top: 160px;
    width: 100%;
    height: 300px;
    background-color: var(--color-primary);
    border-radius: 150px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    z-index: -1;
}

.contact-us__helpBox > .contact-us__title {
    max-width: 400px;
    margin-bottom: 0;
}
.contact-us__helpBox {
    margin-bottom: 100px !important;
}

.contact-us__subtitle {
    max-width: 400px;
    margin-top: 30px;
    font-family: "Public-Sans-Reg", serif;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}

.contact-us__form {
    display: flex;
    width: 100%;
    max-width: 670px;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px auto;
    justify-content: space-between;
    transition: 1s ease-in;
}

.contact-us__input.danger::after {
    background: red;
    color: red;
    content: "sdasdasd";
}

.form-input-container > .contact-us__input {
    width: 100%;
}

.success-field {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
}

.success-field > div {
    margin-bottom: 10px;
}

.form-input-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.contact-us__input {
    width: 100%;
    max-width: 320px;
    margin-bottom: 25px;
}

.contact-us__message {
    max-width: inherit;
    width: 100%;
    height: 150px;
    resize: none;
}

.contact-us__form__submit {
    max-width: 250px;
    width: 100%;
    height: 65px;
    margin: 25px auto;
    background-color: var(--color-primary-green);
    border-radius: 8px;
    font-family: "Public-Sans", serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}
.form-input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    max-width: 320px;
    margin-top: 20px;
}
.form-textarea-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.form-danger-message {
    position: absolute;
    color: red;
    font-size: 14px;
    bottom: 0px;
    line-height: 18px;
}

.form-textarea-container > .form-input {
    max-width: 100%;
    width: 100%;
    min-height: 100%;
}

@media screen and (max-width: 900px) {
    .blueBackground {
        width: 90%;
        height: 150px;
    }
    .contact-us__form {
        flex-direction: column;
    }
    .contact-us__input {
        max-width: 100%;
    }
    .contact-us__subtitle {
        margin-top: 10px;
        font-size: 15px;
    }
    .form-input-container {
        max-width: 100%;
        width: 100%;
    }
    .contact-us__helpBox {
        text-align: center;
        margin-bottom: 10px !important;
    }
}

@media screen and (max-width: 1220px) {
    .blueBackground {
        max-width: 95%;
    }
}
