.navigation {
    display: flex;
    max-height: 100px;
    height: 100%;
}

.navigation__sub {
    display: none;
    width: 250px;
    padding: 13px 12px;
    background: #ffffff;
    text-align: left;
    border: 1px solid rgba(179, 182, 196, 0.102901);
    box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 0.254234);
    border-radius: 10px;
    position: absolute;
    top: 60px;
    z-index: 11;
    border: none;
    overflow: hidden;
}

.navigation__sub__language {
    width: 130px;
    padding: 10px 0 10px 0 !important;
}

.navigation__sub__language > .navigation__sub__item {
    padding: 12px 16px;
}

.navigation__sub__item {
    font-family: "Public-Sans", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
}
.navigation__sub__item.disabled {
    pointer-events: none;
}

.navigation__sub__item > a {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: 12px 16px;
    color: black;
}

.navigation__language__iso {
    text-transform: capitalize;
}

.navigation__sub__item > a:hover {
    background-color: var(--color-primary-light);
    font-weight: 600;
}

.navigation__sub__item:hover {
    background-color: #f4f6f8;
}
.navigation__item {
    display: flex;
    max-height: 40px;
    font-family: "Public-Sans", serif;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.navigation__item:not(:last-child) {
    margin-right: 56px;
    max-width: 150px;
    word-break: break-all;
}

.navigation__item.active > .navigation__sub {
    display: block;
}

.navigation__item.active > span {
    color: var(--color-primary);
}
.navigation__item > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.navigation__item > span:not(.navigation__language__container):after {
    background: url("../media/icons/arrow_down.svg");
    background-size: contain;
    width: 10px;
    height: 6px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 14px;
    transition: 0.3s ease-in-out;
    content: "";
}

.active__route > span:not(.navigation__language__container):before {
    position: absolute;
    content: "";
    top: 35px;
    width: 30px;
    height: 2px;
    background: var(--color-primary);
    display: block;
    bottom: 9px;
}

.active__route > span:not(.navigation__language__container):before {
    content: "";
    width: 30px;
    height: 2px;
    background: var(--color-primary);
    display: block;
    left: calc(50% - 25px);
    position: absolute;
    bottom: 9px;
}

.navigation__item.active > span:not(.navigation__language__container):after {
    transform: rotate(-180deg);
}

.navigation__language {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    background: #ffffff;
    border-radius: 10px;
    font-size: 14px;
    background-color: transparent;
}

.navigation__language__icon {
    border-radius: 50%;
}

.navigation__language__container {
    width: 25px;
    height: 25px !important;
    margin-right: 10px;
    background: #6667674d;
    border-radius: 50%;
    justify-content: center;
    position: relative;
}

.mobile {
    position: absolute;
    width: 100%;
    height: 100%;

    left: 0px;
    top: 100px;
    max-height: 100%;
    margin-right: 0px !important;
    background-color: var(--color-primary-light);
    flex-direction: column;
    z-index: 9999;
}
.mobile .navigation__item {
    max-width: 100%;
    max-height: none;
    margin: 0 auto;
    margin-top: 40px;
    flex-direction: column;
    width: 90%;
}

.mobile .navigation__item > span {
    justify-content: space-between;
    width: 95%;
}

.mobile .navigation__sub {
    position: relative;
    overflow: visible;
    box-shadow: none;
    width: 95%;
    top: 0;
}

.mobile .active__route {
    display: none;
}

@media screen and (max-width: 1470px) {
    .navigation__sub {
        right: -70px;
    }
    .mobile .navigation__sub {
        right: 0;
    }
    .navigation {
        margin-right: 60px;
    }
}
