.home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.home__right video {
    max-width: 1200px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: -15vh;
}
.home__right {
    display: flex;
    flex-direction: row;
    /* min-height: 480px; */
    max-height: 400px;
    z-index: -1;
}

.home__left {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.home__content {
    max-width: 520px;
}

.home__banner {
    width: 100%;
    /* background: url("../../assets/media/home_banner.svg") no-repeat; */
    background-size: contain;
}

.btn-business {
    margin-right: 20px;
}

.home__btn-container {
    margin-bottom: 100px;
}

.home__social__title {
    font-family: "Public-Sans", serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 10px;
}

.home__social__icons:hover {
    transform: rotate(-160deg);
}

.facebook-icon {
    background: url("../../assets/media/icons/facebook.svg") no-repeat center;
}

.linkedin-icon {
    background: url("../../assets/media/icons/linkedin.svg") no-repeat center;
}

.twitter-icon {
    background: url("../../assets/media/icons/twitter.svg") no-repeat center;
}

.buttons-pair {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
}

.home__texts p {
    margin-top: 15px;
    font-weight: 100;
    font-weight: lighter !important;
}

@media only screen and (max-width: 900px) {
    .home {
        flex-direction: column;
    }
    .home__right {
        max-height: 600px;
        margin-left: 0px;
        margin-top: 30px;
    }
    .home__right video {
        margin-top: 10px;
    }
}
