.loading__container {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
}
.lds-dual-ring {
    align-self: center;
    max-width: 30px;
    max-height: 30px;
    margin-left: auto;
    margin-right: 40px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: var(--color-primary-green) transparent
        var(--color-primary-green) transparent;
    animation: lds-dual-ring 1.2s linear infinite;

    &.center {
        margin: auto;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
