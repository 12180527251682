.solutions__cardBox {
    margin-top: 60px;
    flex-wrap: wrap;
    justify-content: center;
}

.solutions__cardBox > li {
    margin: 10px;
}

.solutions__joinBox {
    width: 100%;
    height: 304px;
    padding: 50px 10px;
    margin-top: 70px;
    box-sizing: border-box;
    background-color: var(--color-primary);
    box-shadow: 0 12px 24px -12px rgba(9, 56, 176, 0.2);
    border-radius: 16px;
    text-align: center;
}

.solutions__joinBox__container {
    max-width: 600px;
    margin: 0 auto;
}

.solutions__joinBox__container .input__withBtn__box {
    margin-top: 30px;
}

.solutions__joinBox__title {
    margin-bottom: 0;
}

.solutions__joinBox__subtitle {
    margin-top: 18px;
    font-family: "Public-Sans", serif;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
}
