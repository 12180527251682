.navigation__sub__categories {
    display: none;
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;
    background: #ffffff;
    text-align: left;
}

.navigation__sub__categories.active {
    display: block;
}

.navigation__sub__category__container {
    display: flex;
    align-items: center;
}

.navigation__sub__category__name:hover {
    background-color: var(--color-primary-light);
    font-weight: 600;
}

.navigation__sub__category__name {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: 12px 16px;
    color: black;
}

.navigation__sub__category__toggle__container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 10px;
    height: 30px;
    max-width: 120px;
    width: 100%;
}

.navigation__sub__category__toggle {
    background: url("../media/icons/arrow_down.svg");
    background-repeat: no-repeat;
    width: 10px;
    height: 6px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    text-align: center;
    content: "";
    transition: 0.3s ease-in-out;
    transform: rotate(0deg);
}

.navigation__sub__category__toggle.active {
    transition: 0.3s ease-in-out;
    transform: rotate(-180deg);
}

.mobile_sub {
    background: var(--color-primary-light) !important;
}
