.wizdraw {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wizdraw__bg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wizdraw__title {
    color: var(--color-primary);
}

.wizdraw__info {
    flex-wrap: wrap;
    margin-bottom: 40px;
    font-family: "Public-Sans", serif;
}

.wizdraw__info__title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-primary);
}

.wizdraw__info:before {
    content: url("../../../assets/media/icons/tick_square.svg");
    width: 24px;
    height: 24px;
    margin-right: 25px;
    vertical-align: middle;
}

.wizdraw__info__text {
    margin-left: 50px;
    font-size: 16px;
    line-height: 19px;
}

.wizdraw__downloadBtn {
    padding: 12px 50px;
    margin-top: 23px;
    background-color: var(--color-primary-green);
    color: #ffffff;
    box-shadow: 0 25px 25px -13px rgba(9, 56, 177, 0.05);
    border-radius: 40px;

    font-family: "Public-Sans", serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    cursor: pointer;
}

.wizdraw__googlePlay,
.wizdraw__appStore {
    display: inline-block;
    height: 40px;
    border-radius: 10px;
}

.wizdraw__googlePlay {
    width: 135px;
    margin-right: 12px;
    background: url("../../../assets/media/icons/google_appStor_sprite.svg")
        no-repeat left;
}

.wizdraw__appStore {
    width: 120px;
    background: url("../../../assets/media/icons/google_appStor_sprite.svg")
        no-repeat right;
}

.wizdraw__download {
    text-align: center;
    margin-top: 70px;
}
