.input_card_container {
    position: relative;
    display: flex;
    $this: &;
    flex-direction: column;
    justify-content: center;
    max-width: 1120px;
    min-height: 380px;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: hsl(227, 94%, 34%);
    margin: auto;
    border-radius: 16px;
    margin-bottom: 170px;
    margin-top: 30px;
    .children {
        display: flex;
        justify-content: center;
        max-width: 760px;
        width: 100%;
    }
    .title {
        display: flex;
        font-size: 38px;
        color: white;
        flex-direction: row;
        margin-bottom: 20px;
        > p {
            margin-left: 5px;
        }
        &--green {
            color: #05d09a;
        }
    }
    .subtitle {
        color: white;
        font-size: 24px;
        margin-bottom: 30px;
    }
    .airplanes {
        position: absolute;

        height: 100%;
        width: 100%;
        .bgImg {
            max-width: 90px;
            width: 100%;
            height: auto;
        }
    }

    .bannerImg {
        position: absolute;
        right: 90px;
        top: 50%;
    }
    &.select-box {
        max-height: 380px;
        height: 100vh;
        .airplanes {
            .airplane_0 {
                position: absolute;
                right: -10px;
                top: 40px;
            }
            .airplane_1 {
                position: absolute;
                top: -80px !important;
                left: 14vw;
            }
            .airplane_2 {
                position: absolute;
                top: 200px !important;
                left: 3vw;
            }
        }
    }
    &.country-box {
        min-height: 460px;
        max-height: 460px;
        height: 100vh;
        .airplanes {
            .airplane_0 {
                position: absolute;
                right: -10px;
                top: 100px;
            }
            .airplane_1 {
                position: absolute;
                transform: scaleX(-1);
                top: 74px;
                left: 14vw;
            }
            .airplane_2 {
                position: absolute;
                top: 300px;
                transform: scaleX(-1);
                left: 5px;
            }
        }
        .bannerImg {
            top: 190px;
            right: 40px;
        }
    }
    &.mail-box {
        top: 80px;
        .bannerImg {
            display: none;
        }
    }
    &.blank {
        background-color: transparent;
        .title {
            color: black;
        }
        .subtitle {
            color: black;
        }
        .bannerImg {
            display: none;
        }
        .children {
            width: 600px;
        }
        margin-bottom: 0px;
    }
}
