.selectbox {
    max-width: 600px;
    width: 100%;
    min-height: 60px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: left;
    position: relative;
    top: -10px;
    background: white;
}

.selectbox:focus {
    outline: none;
}

.selectbox_active {
    border-radius: 10px;
}

.selectbox__title {
    display: flex;
    width: 100%;
    padding: 20px 27px;
    box-sizing: border-box;
    font-family: "Public-Sans-Light", serif;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
}

.selectbox__title:after {
    position: absolute;
    top: calc(50% - 3px);
    right: 27px;
    background: url("../media/icons/arrow_down.svg");
    background-size: contain;
    width: 10px;
    height: 6px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 14px;
    transition: 0.3s ease-in-out;
    content: "";
}

.selectbox_active > .selectbox__title:after {
    transform: rotate(-180deg);
}

.selectbox__items {
    width: 100%;

    z-index: 999;
    position: absolute;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    overflow: auto;
}

.selectbox__item {
    padding: 12px 27px;
    width: 73%;
    font-family: "Public-Sans-Reg", serif;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
}

.selectbox__item:hover {
    background-color: var(--color-primary-light);
    border-radius: 0 10px 10px 0;
    font-weight: 600;
}

/* width */
.selectbox__items::-webkit-scrollbar {
    position: absolute !important;
    width: 4px;
    left: -3px;
}

.selectbox__item.selected {
    background-color: var(--color-primary-light);
    border-radius: 0 10px 10px 0;
    font-weight: 600;
}

/* Track */
.selectbox__items::-webkit-scrollbar-track {
    background-color: var(--color-primary-light);
    border-radius: 50px;
}

/* Handle */
.selectbox__items::-webkit-scrollbar-thumb {
    background-color: #66676780;
    border-radius: 50px;
}

/* Handle on hover */
.selectbox__items::-webkit-scrollbar-thumb:hover {
    background: #5d5d5d;
}
