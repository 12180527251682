.google_map__container {
    width: 100%;
    height: 100%;
    z-index: 9999;
    position: fixed;
    backdrop-filter: grayscale(50%) blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
