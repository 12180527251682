.country__input__container {
    position: absolute;
    display: flex;
    max-width: 780px;
    flex-direction: column;
    width: 100%;
}
.country__input__container > .selectbox {
    display: none;
    max-width: 100%;
    top: 0;
    z-index: 1;
}

.country__input__container > .selectbox.active {
    display: block;
}

.input__withBtn__box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 999;
    width: 100%;
    background-color: white;
    border: 1px solid var(--color-primary-light);
    border-radius: 10px;
    box-sizing: border-box;
    outline: none;
}
.input__withBtn__icon {
    margin: 0 0 0 22px;
    width: 30px;
}

.country_name_container {
    display: flex;
    flex-direction: row;
}
.country_name_container img {
    margin-right: 25px;
}

.input__withBtn {
    width: 60%;
    min-height: 60px;
    background-color: transparent;
    color: #666767;
    font-family: "Public-Sans-Light", serif;
    font-size: 16px;
    outline: none;
    line-height: 32px;
    margin-left: 16px;
}

.input__withBtn.danger::placeholder {
    font-size: 13px;
    color: red;
}
.input__withBtn.success::placeholder {
    color: black;
    font-weight: 600;
}
.input__withBtn__btn {
    width: 100%;
    max-width: 150px;
    padding: 14px 20px;
    background-color: var(--color-primary-green);
    color: #ffffff;
    border-radius: 10px;
    font-family: "Public-Sans", serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: 1s ease-in-out;
}

.input__withBtn__btn.disabled {
    background-color: #66676766;
    cursor: default;
    pointer-events: none;
}

.input__withBtn__btn.valid {
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin-right: 30px;
    color: transparent;
    background-image: url("../../assets/media/success_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
}
