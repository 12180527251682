.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 70px;
    > div {
        margin-bottom: 40px;
    }
    > div:last-child {
        margin-left: 50px;
    }
    &.sm {
        max-width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    .container {
        align-items: center;
        flex-direction: column;
        &.reversed {
            flex-direction: column-reverse;
        }
        > div:last-child {
            margin-left: 0px;
        }
    }
}
