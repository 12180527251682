.faq__helpBox {
    position: relative;
    width: 100%;
    height: 370px;
    box-sizing: border-box;
    justify-content: center;
    background-color: var(--color-primary);
    box-shadow: 0 12px 24px -12px rgba(9, 56, 176, 0.2);
    border-radius: 16px;
    text-align: center;
}

.faq__answerBox__date {
    font-size: 24px;
    line-height: 28px;
    color: #666767;
}

.faq__answerBox__answer {
    margin-top: 40px;
    font-family: "Public-Sans-Reg", serif;
    font-size: 16px;
    line-height: 19px;
}

.faq__answerBox__footer {
    margin-top: 40px;
    padding: 25px 25px 25px 0;
    border-top: 1px solid #66676733;
    line-height: 19px;
}

.faq__answerBox__is-useful {
    margin-right: 40px;
}

.faq__thumb {
    background: url("../../../assets/media/icons/thumb_sprite.svg") no-repeat;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    cursor: pointer;

}
.faq__thumb:hover{
    opacity:1!important;
}

.faq__thumb_down {
    background-position: right;
    margin-left: 12px;
    transform:scale3d(-1,-1,-1);
}
