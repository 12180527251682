.career__content {
    background: url("../../../assets/media/career_banner.svg") no-repeat right;
    background-size: contain;
    min-height: 400px;
}

.career__subtitle {
    margin-top: 90px;
    text-align: center;
    font-family: "Public-Sans", serif;
    font-size: 24px;
    line-height: 28px;
}