.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 520px !important;
    img {
        margin-right: 10px;
    }
}
.socialPlatforms {
    margin-top: 20px;
    bottom: 0;
}
.leftSide {
    max-width: 522px;
}
.headerText {
    margin-bottom: 20px;
}
.topInfo {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 20px;
}
.offersList {
    .item {
        display: flex;
        margin-top: 10px;
    }
    img {
        margin-right: 14px;
    }
}
.bottomInfo {
    color: #666767;
    font-size: 16px;
    margin-top: 15px;
}
