.contentBox {
    max-width: 500px;
    width: 100%;
    text-align: left;
}

.contentBox__subtitle {
    margin-top: 25px;
    font-family: "Public-Sans", serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: 900;
}

.contentBox__description {
    margin-top: 16px;
    font-family: "Public-Sans-Light", serif;
    font-size: 16px;
    line-height: 19px;
    text-align: justify !important;
}

.contentBox__row:not(:last-child) {
    margin-bottom: 50px;
}

.contentBox__learnMoreBtn {
    padding: 12px 20px;
    background-color: var(--color-primary-green);
    border-radius: 10px;
    font-family: "Public-Sans", serif;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-align: center;
}
.title-big {
    white-space: pre-line;
}
