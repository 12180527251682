.sendTo {
    width: 100%;
    height: 500px;
    background-color: var(--color-primary);
    box-shadow: 0 12px 24px -12px rgba(9, 56, 176, 0.2);
    border-radius: 16px;
    text-align: center;
    background-image: url("../../../assets/media/icons/plane_green_1.svg"),
    url("../../../assets/media/icons/plane_light.svg"),
    url("../../../assets/media/icons/plane_green.svg");
    background-repeat: no-repeat;
    background-position: 1% 75%, 16% 10%, 100% 34%;
    position: relative;
}

.sendTo__people {
    position: absolute;
    right: 25px;
    bottom: -50px;
    z-index: 0;
}

.sendTo__container {
    max-width: 780px;
    width: 100%;
    margin: 0 auto;
}

h1.sendTo__title {
    color: #FFFFFF;
    margin-bottom: 25px;
}

.sendTo__description {
    margin-bottom: 50px;
    font-family: "Public-Sans", serif;
    font-size: 16px;
    line-height: 19px;
    color: #E6E6E6;
}

.sendTo__searchBox .input__withBtn {
    background-image: url("../../../assets/media/icons/search.svg");
    background-repeat: no-repeat;
    background-position: 23px center;
}

.sendTo__searchBox {
    width: 100%;
    position: relative;
    z-index: 11;
}

.sendTo__searchBox.active > .sendTo__searchInp {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.sendTo__searchBox.active > .sendTo__resultBox__container {
    display: block;
}

.sendTo__resultBox__container {
    display: none;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid var(--color-primary-light);
    box-shadow: 0 12px 24px -12px rgba(9, 56, 177, 0.15);
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;

    position: absolute;
    left: 0;
    top: 64px;
}

.sendTo__searchBox__resultBox {
    width: calc(100% - 150px);
    text-align: left;
}

.endTo__searchBox__result {
    padding: 18px;
    border-radius: 0 10px 10px 0;
    cursor: pointer;

    font-family: "Public-Sans", serif;
    font-size: 16px;
    line-height: 19px;
}

.endTo__searchBox__result:hover {
    background-color: var(--color-primary-light);
}

.endTo__searchBox__result > span:before {
    content: url("../../../assets/media/icons/location.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 18px;
}