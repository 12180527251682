.footer {
    position: relative;
    padding: 20px 0 20px 0;
    width: 100%;
    background-color: var(--color-primary);
    color: #ffffff;
    font-family: "Public-Sans-Reg", serif;
    bottom: 0;
    z-index: 999;
    margin: auto;
    margin-top: auto;
    margin-bottom: 0;
}
.footerContainer {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    justify-content: space-around;
    margin-left: 13vw;
}
.infoContainer {
    display: flex;
    justify-content: space-around;
    max-width: 800px;
    width: 100%;
}

.home__footer__mail {
    color: white;
    &:hover {
        text-decoration: underline;
    }

    &::before {
        content: url("../../assets/media/icons/message-light.svg");
        width: 17px;
        display: inline-block;
        margin: 0 8px 0 0px;
        vertical-align: middle;
    }
}

.home__footer__number {
    color: white;
    &:hover {
        text-decoration: underline;
    }

    &::before {
        content: url("../../assets/media/icons/whatsapp-light.svg");
        display: inline-block;
        width: 16px;
        margin: 0 8px 0 0;
        vertical-align: middle;
    }
}
.home__phone__number {
    color: white;
    &:hover {
        text-decoration: underline;
    }

    &::before {
        content: url("../../assets/media/icons/phone.svg");
        display: inline-block;
        width: 17px;
        margin: 0 0 8px 0;
        vertical-align: text-top;
    }
}

.contactUs {
    margin-bottom: 10px;
    width: 120px;
}

@media only screen and (max-width: 970px) {
    .footerContainer {
        flex-direction: column;
    }
}
@media only screen and (max-width: 970px) {
    .infoContainer {
        flex-direction: column;
    }
}
