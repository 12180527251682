.accordion {
    margin: 45px auto;
    max-width: 800px;
    width: 100%;
}
.accordion.address {
    margin: 0 auto;
}

.accordion__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding: 4px;
    border-radius: 16px;
    margin-bottom: 25px;
    box-sizing: border-box;
}

.accordion__item__title {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 30px 40px;
    font-family: "Public-Sans", serif;
    font-size: 16px;
    line-height: 5px;
    color: #000000;
    cursor: pointer;
    position: relative;
}

.accordion__item__title:after {
    content: url("../media/icons/arrow_down.svg");
    position: absolute;
    right: 40px;
    padding: 0;
    transition: 0.3s;
}

.accordion__text {
    padding: 30px 40px;
    border-top: 1px solid var(--color-primary-light);
    font-family: "Public-Sans-Reg", serif;
    font-size: 16px;
    line-height: 19px;
}

.accordion__text__container {
    height: 0;
    opacity: 0;
    margin-left: 40px;
    overflow: hidden;
    transition-duration: 1s;
}

.active > .accordion__text__container {
    height: auto;
    opacity: 1;
}

.active > .accordion__item__title:after {
    transform: rotate(-180deg);
}
