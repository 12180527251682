.payment_field_container {
    display: flex;
    margin: auto;
    flex-direction: row;
    margin-top: 50px;
    padding: 75px 30px 75px 30px;
    max-width: calc(550px - 75px);
    max-height: calc(625px - 75px);
    width: auto;
    height: auto;
    background-color: white;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    .context_field {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        width: 100%;
        .footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: rgba(102, 103, 103, 1);
            padding: 0 20px 0 20px;
            margin-top: -30px;
            margin-bottom: 40px;
        }
    }
    .download_link {
        color: #05d09a;
        text-decoration: underline;
        text-align: center;
        margin-bottom: 40px;
        cursor: pointer;
    }
}

.loading {
    position: absolute;
    border-color: rgba(102, 103, 103, 1) transparent rgba(102, 103, 103, 1)
        transparent !important;
}

.field_box {
    position: relative;
    border: 2px rgba(102, 103, 103, 0.2) solid;
    border-radius: 10px;
    min-height: 60px;
    font-size: 16px;
    color: rgba(102, 103, 103, 1);
    margin-bottom: 45px;
    height: 100%;
    span::after {
        transition: 0.3s ease-out;
    }
    &.active {
        span::after {
            transform: rotate(-180deg);
        }
    }
    &.flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: 0 27px 0 27px;
    }

    .field_box_label {
        position: absolute;
        top: -13px;
        padding: 2px;
        left: 20px;
        color: rgba(102, 103, 103, 1);
        background-color: white;
    }
    .input_field {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        width: 100%;
        height: 100%;
        :focus {
            outline: none;
        }
        input {
            width: 100%;
            height: 100%;
            font-size: 16px;
            color: rgba(102, 103, 103, 1);
        }
        &.flex {
            display: flex;
            flex-direction: row;
        }
        :last-child {
            margin-left: auto;
        }
    }
}

.outline--gray {
    border: 2px rgba(102, 103, 103, 0.2) solid;
    margin-left: -2px;
}

.selectbox_currency {
    max-width: 60px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.active::after {
    transform: rotate(-180deg);
}

.selectbox_currency_list {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white !important;
    box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 0.254234);
    border-radius: 5px;
    top: 20px;
    width: 50px;
    z-index: 1;
}
.selectbox__currency_list_item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.blue-label-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    position: relative;
    color: #0529a8;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px;
}

.blue-dot {
    width: 10px;
    height: 10px;
    background: #0529a8;
    border-radius: 50%;
}

.blue-label-item {
    align-items: center;
    transition: 0.2s ease-in-out;
    cursor: default;
    &:hover {
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 570px) {
    .blue-dot {
        width: 6px;
        height: 6px;
    }
    .blue-label-items {
        font-size: 4vw;
    }
}

.back__button {
    display: table;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    &::after {
        width: 100%;
        height: 2px;
        content: "";
        display: block;
        background-color: black;
    }
    &::before {
        position: absolute;
        width: 10px;
        left: -15px;
        top: 7px;
        height: 10px;
        display: block;
        content: "";
        background: url("../../../../assets/media/icons/arrow_back.svg");
        background-repeat: no-repeat;
    }
}
