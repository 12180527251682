.map_field_container {
    display: flex;
    flex-direction: column;
    font-family: "Public Sans ", sans-serif;
    font-weight: 400;
    color: #666767;
    .name {
        color: black;
    }
    .text {
        align-self: center;
    }
}

.address_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    width: 100%;
    border-top: 1px solid #f4f6f8;
    padding: 15px 0 15px 0;
}

.address_item__container {
    width: 100%;
    max-width: 700px;
}

.map__container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    .map {
        cursor: pointer;
        color: #05d09a;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: #05d09a;
        }
    }
}
