.header {
    padding: 20px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .header {
        background-color: var(--color-primary-light);
        border-bottom: 1px solid rgba(102, 103, 103, 0.15);
    }
}
