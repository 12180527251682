.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    .col {
        margin: 4px;
        display: block;
        background-color: black;
        width: 100%;
        height: 2px;
        transition: 0.4s ease-in-out;
    }

    &.opened {
        .col {
            opacity: 0%;
        }
        .col-close__container {
            .close-1 {
                transform: rotate(45deg);
            }
            .close-2 {
                transform: rotate(-45deg);
            }
        }
    }
}

.col-close__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    .col-close {
        position: absolute;
        display: block;
        background-color: black;
        width: 100%;
        height: 2px;
        transition: 0.4s ease-in-out;
    }
}
