.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
    background-color: #334253;
    color: #ffffff;
    font-family: "Public-Sans-Reg", serif;
    font-size: 14px;
    line-height: 17px;
}

.footer__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
    flex-grow: 1;
    width: 90%;
    padding: 60px 0 60px 0;
    border-bottom: 1px solid #ffffff80;
}
.footer__navigation {
    display: flex;
    flex-direction: column;
}

/* temprorary */
.footer__contact__mail:before {
    content: url("../../assets/media/icons/message-light.svg");
    display: inline-block;
    margin-right: 8px;
    vertical-align: text-top;
}
.footer__navigation > li > a {
    color: white !important;
}

.footer__navigation > li {
    margin-bottom: 12px;
}

.footer__contact__number:before {
    content: url("../../assets/media/icons/whatsapp-light.svg");
    margin-right: 8px;
    vertical-align: text-top;
}
.footer__phone__number:before {
    content: url("../../assets/media/icons/phone.svg");
    margin-left: 4px;
    margin-right: 8px;
    vertical-align: text-top;
}

/* .footer__navigation__item.disabled {
    pointer-events: none;
} */

.footer__navigation__item:hover {
    text-decoration: underline;
}

.footer__container__col__title {
    display: inline-block;
    margin-bottom: 15px;
    font-family: "Public-Sans", serif;
    font-size: 16px;
    line-height: 19px;
}

.footer__container__col {
    margin-right: 60px;
    margin-bottom: 30px;
}

.footer__copyright {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    color: #ffffff;
    max-width: 600px;
    align-self: center;
    width: 100%;
    margin-top: 27px;
    padding-bottom: 27px;
    font-family: "Public-Sans-Light", serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}
.footer__copyright_item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}
.footer__copyright_item:not(:last-child)::after {
    content: "";
    display: block;
    background-color: white;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 10px;
}
.footer__pdf_container {
    position: absolute;
}
