.content {
    position: relative;
    max-width: 593px;
    width: 100%;
    height: 433px;
}

.cloud {
    position: absolute;
    &.left {
        left: 0;
        top: 61px;
        width: 67px;
    }
    &.right {
        right: 0;
        z-index: -1;
    }
}
.plane {
    position: absolute;
    width: 62px;
    &.left {
        left: 62px;
        top: 162px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    &.right {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        right: 108px;
        top: 84px;
    }
    &.middle {
        top: 271px;
        right: 200px;
    }
}
.errorMessage {
    flex-direction: column;
    align-items: center;
    max-width: 545px;
    height: 248px;
    width: 100%;
    background: #0529a8;
    border-radius: calc(
        90px + (200 - 90) * (100vw - 320px) / (1920 - 320)
    ) !important;
    color: white;
    margin-top: 37px;
    h1 {
        line-height: 76px;
        font-size: calc(
            70px + (97 - 70) * (100vw - 320px) / (1920 - 320)
        ) !important;
        margin-bottom: 12px;
    }
    p {
        font-size: 24px;
    }
}
.character {
    position: absolute;
    right: 26px;
    width: 150px;
    top: 195px;
}

.flexCentered {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .plane {
        display: none;
    }
    .character {
        right: auto;
        top: 250px;
    }
}
